import { html } from 'lit';

export class BrandText {
    constructor(i18n) {
        this.i18n = i18n;
    }

    getBrandText(brand) {
        const termsAndConditionsLinkText= this.i18n.t({
                id: 'onboarding.dialog.content.first-screen.title.t-and-c-link-text.dba',
                message: 'terms and conditions.',
                comment: 'Text for terms and conditions',
            })

        const brandText = {
            dba: {
                firstScreen: {
                    mainText: html`<div class="text-center">
                        <h1 class="h4">
                            ${this.i18n.t({
                                id: 'onboarding.dialog.content.first-screen.title.dba',
                                message: 'Welcome to a whole new DBA experience',
                                comment: 'Title for the onboarding first screen',
                            })}
                        </h1>
                        <p class="modal-main-text">
                            ${this.i18n.t({
                                id: 'onboarding.dialog.content.first-screen.text.dba',
                                message: "DBA has introduced new features that make it easier to buy and sell used items. By continuing I accept DBA's",
                                comment: 'Text for the onboarding first screen. This text should have an incomplete sentence. The last part of the sentence ("terms and conditions") is in a separate translation.',
                            })}
                           <a href="https://login.schibstedmarketplaces.dk/terms?client_id=66c5e7ee560c9f32a47dbe39&locale=da_DK" target="_blank">${termsAndConditionsLinkText}</a>
                        </p>
                    </div>`,
                    imageDescription: this.i18n.t({
                        id: 'onboarding.dialog.content.first-screen.image.alttext.dba',
                        message: 'DBA logo for new platform introduction',
                        comment: 'Alt text for the first screen image',
                    }),
                },
                secondScreen: {
                    mainText: html`<div class="text-center">
                        <h1 class="h4">
                            ${this.i18n.t({
                                id: 'onboarding.dialog.content.second-screen.title.dba',
                                message: 'View ratings from others',
                                comment: 'Title for the onboarding second screen',
                            })}
                        </h1>
                        <p class="modal-main-text">
                            ${this.i18n.t({
                                id: 'onboarding.dialog.content.second-screen.text.dba',
                                message:
                                    "Check others' experiences with the person you're trading with before you buy or sell.",
                                comment: 'Text for the onboarding second screen',
                            })}
                        </p>
                    </div>`,
                    imageDescription: this.i18n.t({
                        id: 'onboarding.dialog.content.second-screen.image.alttext.dba',
                        message:
                            'Introduction to checking ratings for trade partners. Profile card of Lisa Andersen, a MitID-validated user with a rating of 9.2 from 16 reviews.',
                        comment: 'Alt text for the second screen image',
                    }),
                },
                thirdScreen: {
                    mainText: html`<div class="text-center">
                        <h1 class="h4">
                            ${this.i18n.t({
                                id: 'onboarding.dialog.content.third-screen.title.dba',
                                message: 'Shop like in an online store',
                                comment: 'Title for the onboarding third screen',
                            })}
                        </h1>
                        <p class="modal-main-text">
                            ${this.i18n.t({
                                id: 'onboarding.dialog.content.third-screen.text.dba',
                                message:
                                    'You can now purchase items easily and securely from all over Denmark with DBA [ship-it].',
                                comment: 'Text for the onboarding third screen',
                            })}
                        </p>
                    </div>`,
                    imageDescription: this.i18n.t({
                        id: 'onboarding.dialog.content.third-screen.image.alttext.dba',
                        message:
                            'Shop securely with DBA across Denmark. Example payment summary showing total cost of 345 kr with a MobilePay option.',
                        comment: 'Alt text for the third screen image',
                    }),
                },
                fourthScreen: {
                    mainText: html`<div class="text-center">
                        <h1 class="h4">
                            ${this.i18n.t({
                                id: 'onboarding.dialog.content.fourth-screen.title.dba',
                                message: 'Easy and secure sales',
                                comment: 'Title for the onboarding fourth screen',
                            })}
                        </h1>
                        <p class="modal-main-text">
                            ${this.i18n.t({
                                id: 'onboarding.dialog.content.fourth-screen.text.dba',
                                message:
                                    'No more exchanging personal information or arranging payments. We handle everything for you as a seller, with no extra fees.',
                                comment: 'Text for the onboarding fourth screen',
                            })}
                        </p>
                    </div>`,
                    imageDescription: this.i18n.t({
                        id: 'onboarding.dialog.content.fourth-screen.image.alttext.dba',
                        message: 'Easy, secure sales with DBA. Example shipping options by item size for sellers.',
                        comment: 'Alt text for the fourth screen image',
                    }),
                },
            },
            tori: {
                firstScreen: {
                    mainText: html`<div class="text-center">
                        <h1 class="h4">
                            ${this.i18n.t({
                                id: 'onboarding.dialog.content.first-screen.title.tori',
                                message: 'Welcome to the renewed Tori!',
                                comment: 'Title for the onboarding first screen',
                            })}
                        </h1>
                        <p class="modal-main-text">
                            ${this.i18n.t({
                                id: 'onboarding.dialog.content.first-screen.text.tori',
                                message:
                                    'The familiar and secure Tori is now even safer and easier to use. For example, you can make transactions through Tori’s own payment and shipping service ToriDiili.',
                                comment: 'Text for the onboarding first screen',
                            })}
                        </p>
                    </div>`,
                    imageDescription: this.i18n.t({
                        id: 'onboarding.dialog.content.first-screen.image.alttext.tori',
                        message: 'A red circle that contains the words "ToriDiili - secure payment, smooth delivery".',
                        comment: 'Alt text for the first screen image',
                    }),
                },
                secondScreen: {
                    mainText: html`<div class="text-center">
                        <h1 class="h4">
                            ${this.i18n.t({
                                id: 'onboarding.dialog.content.second-screen.title.tori',
                                message: 'Shop like in an online store',
                                comment: 'Title for the onboarding second screen',
                            })}
                        </h1>
                        <p class="modal-main-text">
                            ${this.i18n.t({
                                id: 'onboarding.dialog.content.second-screen.text.tori',
                                message:
                                    'Find treasures all over Finland with just a few clicks! You can recognise the ads belonging to the service from the ToriDiili logo.',
                                comment: 'Text for the onboarding second screen',
                            })}
                        </p>
                    </div>`,
                    imageDescription: this.i18n.t({
                        id: 'onboarding.dialog.content.second-screen.image.alttext.tori',
                        message:
                            'A smartphone displaying a Tori listing and a button with the text "Make a ToriDiili bid".',
                        comment: 'Alt text for the second screen image',
                    }),
                },
                thirdScreen: {
                    mainText: html`<div class="text-center">
                        <h1 class="h4">
                            ${this.i18n.t({
                                id: 'onboarding.dialog.content.third-screen.title.tori',
                                message: 'Sell without extra messaging',
                                comment: 'Title for the onboarding third screen',
                            })}
                        </h1>
                        <p class="modal-main-text">
                            ${this.i18n.t({
                                id: 'onboarding.dialog.content.third-screen.text.tori',
                                message: `When you sell your item with ToriDiili, you don't need to exchange contact details or agree on payments with the buyer – they are handled automatically through Tori!`,
                                comment: 'Text for the onboarding third screen',
                            })}
                        </p>
                    </div>`,
                    imageDescription: this.i18n.t({
                        id: 'onboarding.dialog.content.third-screen.image.alttext.tori',
                        message: `A smartphone displaying a headline "Offer ToriDiili - Tori's new shipping and payment service". An arrow points to the parcel size the seller needs to select for shipping.`,
                        comment: 'Alt text for the third screen image',
                    }),
                },
                fourthScreen: {
                    mainText: html`<div class="text-center">
                        <h1 class="h4">
                            ${this.i18n.t({
                                id: 'onboarding.dialog.content.fourth-screen.title.tori',
                                message: 'Every ToriDiili includes purchase protection',
                                comment: 'Title for the onboarding fourth screen',
                            })}
                        </h1>
                        <p class="modal-main-text">
                            ${this.i18n.t({
                                id: 'onboarding.dialog.content.fourth-screen.text.tori',
                                message: `You can review the seller's ratings and profile before making a purchase decision. Thanks to purchase protection, the payment is transferred to the seller only after you have had a chance to inspect the product after delivery.`,
                                comment: 'Text for the onboarding fourth screen',
                            })}
                        </p>
                    </div>`,
                    imageDescription: this.i18n.t({
                        id: 'onboarding.dialog.content.fourth-screen.image.alttext.tori',
                        message: `Seller's profile in a listing. It shows the name Liisa Andersson, identified person, Tori user since 2012. The seller has 16 reviews with an average rating of 8.7.`,
                        comment: 'Alt text for the fourth screen image',
                    }),
                },
            },
        };
        return brand === 'DBA' ? brandText.dba : brand === 'TORI' ? brandText.tori : {};
    }

    getButtonText() {
        const navButtons = {
            remind: this.i18n.t({
                id: 'onboarding.dialog.button.remind-me-later',
                message: 'Remind me later',
                comment: 'Remind me later button for the onboarding modal',
            }),
            tour: this.i18n.t({
                id: 'onboarding.dialog.button.take-tour',
                message: 'Take the tour',
                comment: 'Take the tour button for the onboarding modal',
            }),
            discoverDba: this.i18n.t({
                id: 'onboarding.dialog.button.discover-dba',
                message: 'Discover the new DBA',
                comment: 'Finish button for the DBA onboarding modal',
            }),
            back: this.i18n.t({
                id: 'onboarding.dialog.button.back',
                message: 'Back',
                comment: 'Back button for the onboarding modal',
            }),
            next: this.i18n.t({
                id: 'onboarding.dialog.button.next',
                message: 'Next',
                comment: 'Next button for the onboarding modal',
            }),
            finish: this.i18n.t({
                id: 'onboarding.dialog.button.finish',
                message: 'Finish',
                comment: 'Finish button for the onboarding modal',
            }),
        };
        return navButtons;
    }
}
