// @ts-nocheck

import WarpElement from '@warp-ds/elements-core';
import '@warp-ds/elements';
import '@warp-ds/icons/elements/chevron-left-24';
import { html, render } from 'lit';
import { BrandText } from './brand-text';
import { showModal } from './onboarding-modal';

/* 
Mount (or entry point) component for the onboarding modal.
Can be used server-side, and when connected on the client will render the onboarding-modal element in a
designated modal div, parallell to the main content.
*/
class OnboardingModalEntrypoint extends WarpElement {
    connectedCallback() {
        super.connectedCallback();

        const { body } = window.document;
        const brand = this.getAttribute('brand') || '';
        const i18n = this.i18n; // Pass Lingui i18n instance
        const brandTextInstance = new BrandText(i18n);
        const brandScreenText = brandTextInstance.getBrandText(brand);
        const buttonText = brandTextInstance.getButtonText();
        const imgBaseURL = this.getAttribute('illustrationbaseurl');

        if (body && showModal()) {
            // Get the modal div or create and attach one.
            let modalDiv = document.querySelector('#modal-div');
            if (!modalDiv) {
                modalDiv = document.createElement('div');
                modalDiv.id = 'modal-div';
                body.appendChild(modalDiv);
            }

            // Render modal.
            render(
                html`<onboarding-modal
                    locale="${this.getAttribute('locale') || 'en'}"
                    messages="${this.getAttribute('messages') || '{}'}"
                    brand="${brand}"
                    .imgBaseURL="${imgBaseURL}"
                    .brandScreenText=${brandScreenText}
                    .buttonText=${buttonText}
                ></onboarding-modal>`,
                modalDiv,
            );
        }
    }
}

if (!customElements.get('onboarding-modal-entrypoint')) {
    customElements.define('onboarding-modal-entrypoint', OnboardingModalEntrypoint);
}
