// @ts-nocheck
import WarpElement from '@warp-ds/elements-core';
import { css, html } from 'lit';
import '@warp-ds/elements';
import '@warp-ds/icons/elements/chevron-left-24';
import pulse from '@finn-no/pulse-sdk';
/* eslint-disable no-unused-vars */

function localStorageAvailable() {
    try {
        window.localStorage.setItem('__test_key', '_');
        window.localStorage.removeItem('__test_key');

        return true;
    } catch (e) {
        return false;
    }
}

export function showModal() {
    let item;

    try {
        item = window.localStorage.getItem('onboarding');
    } catch (e) {
        console.error(e);
    }

    const getHrDiff = (dateItem) => {
        let diff = new Date() - new Date(dateItem);

        return diff / 3600000;
    };

    // If the modal hasn't been shown, or it has been set to remind later and 24 hrs have passed, show modal.
    return localStorageAvailable() && (!item || (item.length > 7 && getHrDiff(item) > 24));
}

// Check whether the element is a cookie consent banner, or any other element with a z-index higher than the modal.
// Needed to exclude the element from the list of inert elements.
function excludeElement(element) {
    if (element) {
        const zIndex = +getComputedStyle(element)?.zIndex;

        const modalZIndex = 20;

        // If the element is at the same level or higher than the modal, or a banner, return true.
        return element.id.includes('sp_message') || zIndex >= modalZIndex;
    }
}

/* 
Onboarding modal component, to be instantiated client-side.
Displays a modal with pages, and can set open or closed state.
Sets main page content to inert when open, to limit tab interaction to the modal only.
*/
class OnboardingModal extends WarpElement {
    static styles = [
        ...WarpElement.styles,
        css`
            .dot-buttons {
                padding-top: min(1vh, 16px);
                padding-bottom: min(3vh, 24px);
            }
            .main-wrapper {
                margin-bottom: min(2vh, 20px);
                width: 100%;
                height: 52vw;
                max-height: calc(85% - 90px);
            }
            .main-wrapper svg {
                width: 100%;
                height: 100%;
            }
            .onboarding-modal {
                height: 560px;
                max-height: 100vh;
                padding-bottom: min(4vh, 30px);
            }
            .modal-main-text {
                max-height: 100px;
                overflow-y: auto;
            }
            *,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}.\\[--w-modal-min-height\\:64\\%\\]{--w-modal-min-height:64%}.\\[--w-modal-width\\:455px\\]{--w-modal-width:455px}.before\\:bg-\\[--w-black\\/95\\]:before{background-color:rgba(var(--w-rgb-black),.95)}.before\\:opacity-25:before{opacity:.25}.gap-16{gap:1.6rem}.overflow-y-hidden{overflow-y:hidden}.before\\:bottom-0:before{bottom:0}.before\\:left-0:before{left:0}.before\\:right-0:before{right:0}.before\\:top-0:before{top:0}.static{position:static}.before\\:absolute:before{position:absolute}.s-bg{background-color:var(--w-s-color-background)}.s-bg-disabled{background-color:var(--w-s-color-background-disabled)}.s-bg-primary{background-color:var(--w-s-color-background-primary)}.shadow-m{box-shadow:var(--w-shadow-m)}.mx-1{margin-left:.1rem;margin-right:.1rem}.p-2{padding:.2rem}.pt-12{padding-top:1.2rem}.transition{transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-backdrop-filter,backdrop-filter;transition-duration:.15s;transition-timing-function:cubic-bezier(.4,0,.2,1)}@media (min-width:480px){.sm\\:px-16{padding-left:1.6rem;padding-right:1.6rem}}@media (min-width:768px){.md\\:px-12{padding-left:1.2rem;padding-right:1.2rem}.md\\:pt-16{padding-top:1.6rem}};
        `,
    ];

    static properties = {
        _showOnboarding: { state: true },
        _screen: { state: true },
        illustrations: {},
        brand: { type: 'string' },
        imgBaseURL: { type: 'string' },
        brandScreenText: { type: 'object' },
        buttonText: { type: 'object' },
    };

    constructor() {
        super();

        this._screen = 1;
        this.pulse = pulse;
        this.brandScreenText = {};
        this.buttonText = {};
        this.imgBaseURL = '';
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('keydown', this._handleKeyDown.bind(this)); // Listen for ESC key

        this.brand = this.getAttribute('brand');
        // Open modal on connected if required.
        if (showModal()) {
            this._setOpen();
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('keydown', this._handleKeyDown.bind(this)); // Cleanup key listener
    }

    _skipOnboarding() {
        this._setClosed();

        try {
            window.localStorage.setItem('onboarding', 'skipped');
        } catch (e) {
            console.error(e);
        }

        this.sendClickEvent('Close');
    }

    _handleKeyDown(event) {
        if (event.key === 'Escape') {
            this._setClosed(); // Close modal on ESC key press
        }
    }

    _finish() {
        this._setClosed();

        try {
            window.localStorage.setItem('onboarding', 'done');
        } catch (e) {
            console.error(e);
        }

        this.sendClickEvent('Close');
    }

    // Get page elements that are not the modal div and should be made inert.
    _getPageContent() {
        const body = document.querySelector('body');

        if (body) {
            return [...body.children].filter(
                (e) => !['STYLE', 'SCRIPT'].includes(e.nodeName) && e.id !== 'modal-div' && !excludeElement(e),
            );
        } else {
            return [];
        }
    }

    // Open modal and control background elements to handle tab behavior and scrolling.
    _setOpen() {
        this._showOnboarding = true;

        this.backgroundPageElements = this._getPageContent();

        this.backgroundPageElements.forEach((element) => {
            // Set background to inert.
            element.inert = true;
        });

        // Disable background scrolling.
        document.body.style.touchAction = 'none';
        document.body.style.overflow = 'hidden';

        this.sendViewEvent(0);
    }

    // Close modal and reset any background element properties.
    _setClosed() {
        this._showOnboarding = false;

        // Reset.
        if (this.backgroundPageElements) {
            this.backgroundPageElements.forEach((element) => {
                element.inert = false;
            });

            // Reset body style to enable background scrolling after close
            document.body.style.cssText = '';
        }
    }

    _previousScreen() {
        this._screen = this._screen - 1;

        this.sendClickEvent('Cancel');

        this.sendViewEvent(this._screen - 1);
    }

    _nextScreen() {
        this._screen = this._screen + 1;

        this.sendViewEvent(this._screen - 1);
    }

    _remindMeLater() {
        this._setClosed();

        // Set the current date time.
        try {
            window.localStorage.setItem('onboarding', new Date().toString());
        } catch (e) {
            console.error(e);
        }

        this.sendClickEvent('Dismiss');
    }

    _setScreen(number) {
        this._screen = number;
    }

    sendClickEvent(intent) {
        this.pulse.trackEvent({
            type: 'Click',
            intent: intent,
            name: 'Onboarding',
            object: {
                type: 'UIElement',
                elementType: 'Button',
                name: 'Onboarding modal',
            },
            vertical: { name: 'common_pages' },
        });
    }

    sendViewEvent(position) {
        this.pulse.trackPageView({
            name: 'Onboarding',
            object: {
                type: 'UIElement',
                elementType: 'Pop up',
                position: position,
                name: 'Onboarding modal',
            },
            vertical: { name: 'common_pages' },
        });
    }

    // Clickable section dot buttons.
    _sectionButtons(activeSection = 1) {
        return html`<div
            class="flex justify-center dot-buttons"
            role="progressbar"
            aria-valuetext="Step ${activeSection}"
        >
            ${new Array(4).fill().map(
                (_, i) =>
                    html`<div class="p-2 mx-1 cursor-pointer" @click="${() => this._setScreen(i + 1)}">
                        <div
                            class="p-4 rounded-full ${i + 1 === activeSection ? 's-bg-primary' : 's-bg-disabled'} mx-1"
                        ></div>
                    </div>`,
            )}
        </div>`;
    }

    /*
    Main modal navigation buttons.
    parameters: button: { text, onClick }
    */
    _navButtons(secondary, primary) {
        return html`<div class="w-full grid justify-center grid-flow-col gap-16 select-none">
            <w-button variant="secondary" @click="${secondary.onClick}"> ${secondary.text} </w-button>
            <w-button variant="primary" @click="${primary.onClick}"> ${primary.text} </w-button>
        </div>`;
    }

    _skipBtn() {
        return html`<w-button
            variant="pill"
            aria-label="${this.i18n.t({
                id: 'onboarding.dialog.header.close-button.area-label',
                message: 'Close',
                comment: 'Area label for cross icon button that will close onboarding modal',
            })}"
            @click="${this._skipOnboarding}"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 16 16"
                class="h-20 w-20 sm:h-24 sm:w-24"
            >
                <title>
                    ${this.i18n.t({
                        id: 'onboarding.dialog.header.close-icon.title',
                        message: 'Cross',
                        comment:
                            'Title for the cross icon svg, that is a part of the button for closing onboarding modal',
                    })}
                </title>
                <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="m12.5 3.5-9 9M3.5 3.5l9 9"
                ></path>
            </svg>
        </w-button>`;
    }

    _handleTouchMove(e) {
        const delta = 5;

        const clientX = e.changedTouches[0]?.clientX;

        if (this.lastStartTouchX) {
            if (clientX - this.lastStartTouchX > delta) {
                if (this._screen > 1) {
                    this._previousScreen();
                    this.lastStartTouchX = null;
                }
            } else if (this.lastStartTouchX - clientX > delta) {
                if (this._screen < 4) {
                    this._nextScreen();
                    this.lastStartTouchX = null;
                }
            }
        }
    }

    _screenMainWrapper(screenNumber, altText, content) {
        return html` <div
            class="block overflow-y-hidden overflow-x-hidden grow shrink px-8 sm:px-16 relative"
            @touchstart=${(e) => (this.lastStartTouchX = e.touches[0]?.clientX)}
            @touchmove=${(e) => this._handleTouchMove(e)}
        >
            <img
                class="main-wrapper"
                src="${this.imgBaseURL}/onboarding-illustration-${screenNumber}.svg"
                alt="${altText}"
            />
            ${content}
        </div>`;
    }

    _navTexts() {
        const buttons = this.buttonText;

        return {
            ...buttons,
        };
    }

    _firstScreen() {
        const { firstScreen } = this.brandScreenText;
        const buttonParamsByBrand =
            this.brand === 'DBA'
                ? [
                      { text: this._navTexts().remind, onClick: this._remindMeLater },
                      { text: this._navTexts().next, onClick: this._nextScreen },
                  ]
                : this.brand === 'TORI'
                  ? [
                        { text: this._navTexts().remind, onClick: this._remindMeLater },
                        { text: this._navTexts().tour, onClick: this._nextScreen },
                    ]
                  : [];

        return html`${this._screenMainWrapper(this._screen, firstScreen.imageDescription, firstScreen.mainText)}
        ${this._sectionButtons(this._screen)} ${this._navButtons(...buttonParamsByBrand)}`;
    }

    _secondScreen() {
        const { secondScreen } = this.brandScreenText;

        return html`${this._screenMainWrapper(this._screen, secondScreen.imageDescription, secondScreen.mainText)}
        ${this._sectionButtons(this._screen)}
        ${this._navButtons(
            { text: this._navTexts().back, onClick: this._previousScreen },
            { text: this._navTexts().next, onClick: this._nextScreen },
        )}`;
    }

    _thirdScreen() {
        const { thirdScreen } = this.brandScreenText;
        return html`${this._screenMainWrapper(this._screen, thirdScreen.imageDescription, thirdScreen.mainText)}
        ${this._sectionButtons(this._screen)}
        ${this._navButtons(
            { text: this._navTexts().back, onClick: this._previousScreen },
            { text: this._navTexts().next, onClick: this._nextScreen },
        )}`;
    }

    _fourthScreen() {
        const { fourthScreen } = this.brandScreenText;
        const buttonParamsByBrand =
            this.brand === 'DBA'
                ? [
                      { text: this._navTexts().back, onClick: this._previousScreen },
                      { text: this._navTexts().discoverDba, onClick: this._finish },
                  ]
                : this.brand === 'TORI'
                  ? [
                        { text: this._navTexts().back, onClick: this._previousScreen },
                        { text: this._navTexts().finish, onClick: this._finish },
                    ]
                  : [];
        return html`${this._screenMainWrapper(this._screen, fourthScreen.imageDescription, fourthScreen.mainText)}
        ${this._sectionButtons(this._screen)} ${this._navButtons(...buttonParamsByBrand)}`;
    }

    _getScreen() {
        switch (this._screen) {
            case 1:
                return this._firstScreen();
            case 2:
                return this._secondScreen();
            case 3:
                return this._thirdScreen();
            case 4:
                return this._fourthScreen();
            default:
                return this._firstScreen();
        }
    }

    render() {
        if (!this._showOnboarding) return html``;

        return html`
            <div
                class='fixed before:bg-[--w-black/95] inset-0 flex sm:place-content-center sm:place-items-center items-end z-20 [--w-modal-min-height:64%] [--w-modal-width:455px] before:content-[""] before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:opacity-25'
            >
                <div
                    role="dialog"
                    aria-modal="true"
                    class="onboarding-modal shadow-m s-bg w-[--w-modal-width] relative transition duration-300 backface-hidden will-change-height rounded-8 mx-0 sm:mx-16 flex flex-col overflow-hidden outline-none pt-12 px-12 md:px-12 md:pt-16 rounded-b-0 sm:rounded-b-8"
                >
                    <div class="flex justify-end">${this._skipBtn()}</div>
                    ${this._getScreen()}
                </div>
            </div>
        `;
    }
}

if (!customElements.get('onboarding-modal')) {
    customElements.define('onboarding-modal', OnboardingModal);
}
