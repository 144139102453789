import { LitElement } from 'lit';
import { MessageBus } from '@podium/browser';

class TopbarDataService extends LitElement {
    #unauthorised = false;

    static get properties() {
        return {
            loginId: { type: String, attribute: 'login-id' },
            spidId: { type: String, attribute: 'spid-id' },
            apiUrl: { type: String, attribute: 'api-url' },
        };
    }

    get topbarEl() {
        return document.querySelector('finn-topbar');
    }

    async fetch() {
        // @ts-ignore
        if (!this.loginId || !this.spidId || this.#unauthorised) return;

        // Handles any eventual `Unhandled promise rejection` errors,
        // which happens when the fetch fails or parsing of the result to json fails.
        try {
            // @ts-ignore
            const res = await fetch(new URL(this.apiUrl), {
                credentials: 'include',
            });

            if (res.ok) {
                const result = await res.json();
                const { hasNotifications, profileImage, profileName, profileEmail } = result;

                // @ts-ignore
                this.topbarEl.hasNotifications = Number(hasNotifications) > 0;
                // @ts-ignore
                this.topbarEl.profileImageURL = profileImage;
                // @ts-ignore
                this.topbarEl.profileName = profileName;
                // @ts-ignore
                this.topbarEl.profileEmail = profileEmail;
            } else if (res.status === 401) {
                this.#unauthorised = true;
            }
        } catch (e) {
            console.trace('finn-topbar: failed when fetching/parsing user info', e);
        }
    }

    connectedCallback() {
        super.connectedCallback();

        this.fetch();
        setInterval(this.fetch.bind(this), 1000 * 30);

        const messageBus = new MessageBus();
        messageBus.subscribe('notifications', 'allWereViewed', () => {
            if (typeof window !== 'undefined') {
                // @ts-ignore
                this.topbarEl.hasNotifications = false;
            }
        });
    }
}

customElements.define('topbar-data-service', TopbarDataService);
